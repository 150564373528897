var timeout;

function handleMouseActivity() {
  clearTimeout(timeout);

  // Add class to body to show the scrollbar
  document.body.classList.remove("no-scroll");

  // Set a timeout to hide the scrollbar after a certain duration of mouse inactivity
  timeout = setTimeout(function () {
    document.body.classList.add("no-scroll");
  }, 2000); // Adjust the duration (in milliseconds) as needed
}

// // Add 'mousemove' event listener
// document.addEventListener("mousemove", handleMouseActivity);

// // Add 'wheel' event listener
// document.addEventListener("wheel", handleMouseActivity);

window.addEventListener("scroll", function () {
  var header = document.querySelector(".navHeader");
  var scrollPosition = window.scrollY;

  if (scrollPosition > 0) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
});

document
  .getElementsByClassName("menu-toggle")[0]
  .addEventListener("click", function () {
    var navElements = document.getElementsByClassName("nav");
    var menuToggle = document.getElementsByClassName("menu-toggle")[0];

    for (var i = 0; i < navElements.length; i++) {
      navElements[i].classList.toggle("mobile-nav");
    }

    menuToggle.classList.toggle("is-active");
  });
